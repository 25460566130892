import React, { useState, useEffect, useRef } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap';
import Header from "../components/Campaign/header";
import Banner from "../components/Campaign/banner";
import GridBlock from "../components/Campaign/GridBlock";
import ContentBlock from "../components/Campaign/ContentBlock";
import Valuation from "../components/Valuation/Valuation";
import GoogleReview from '../components/Campaign/google-reviews'
import ImageTransform from "../components/common/ggfx-client/module/components/image-transform";
import Footer from "../components/Campaign/footer";
import '../scss/pages/campaign.scss';

import $ from 'jquery'




const CampaignTemplate = (props) => {
  const { data } = props
  const { strapiCampaigns, strapiGlobalConfig } = data;
  const image_url = strapiCampaigns.Banner_Image && strapiCampaigns.Banner_Image.internal.description ? strapiCampaigns.Banner_Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
  let processedImages = JSON.stringify({});
  if (strapiCampaigns?.imagetransforms?.Banner_Image_Transforms) {
    processedImages = strapiCampaigns.imagetransforms.Banner_Image_Transforms;
  }
  console.log('campaings', props, strapiCampaigns)
  const gotohash = (event) => {
    var thishash = event;
    $('html, body').animate({
      scrollTop: 0
    }, 1000);
  }
  return (
    <>
      <div className="campaign">
        <Header />
        <Banner {...strapiCampaigns} image_url={image_url} processedImages={processedImages} />
        {strapiCampaigns.Campaign_Modules && strapiCampaigns.Campaign_Modules.map((cam_module, k) => {
          return (
            <div key={k}>
              {cam_module.Grid_List &&
                <GridBlock {...cam_module} gotohash={gotohash} />
              }
              {cam_module.Content_Block &&
                <ContentBlock {...cam_module} gotohash={gotohash}  strapiCampaigns={strapiCampaigns} />
              }
              {cam_module.Content_After && cam_module.Title && cam_module.Content_Before && cam_module.Phone_Number &&
                <div id="valuation-request">
                  <Valuation Title={cam_module.Title} Content_Before={cam_module.Content_Before} Content_After={cam_module.Content_After}
                    Phone_Number={cam_module.Phone_Number} />
                </div>
              }
              {cam_module.Select_Module === "Google_Reviews" &&
                <GoogleReview />
              }

            </div>
          )
        })}
        {/* <GoogleReview  /> */}
        <Footer footerDetails={strapiGlobalConfig} />
      </div>
    </>
  )

}

export const pageQuery = graphql`
  query CampaignQuery($slug: String!) {
    strapiCampaigns(Slug: {eq: $slug}) {
        id
        Slug
        strapiId
        Title
        Meta_Description
        Meta_Title 
        Banner_Content
        Banner_Image {
          publicURL
          internal {
            description
          }
        } 
        imagetransforms {
          Banner_Image_Transforms
          List_Image_Transforms 
        }
        Contact_Number
        Campaign_Modules {
          Grid_List {
            Content
            Title
            Image {
              publicURL
              internal {
                description
              }
            }
          }
          Select_Module
          Content_Block {
            BG_Color
            CTA_Label
            CTA_Link
            Content
            Title
            Image {
              publicURL
              internal {
                description
              }
            }
          }
          Content
          Title
          CTA_Label
          CTA_Link
          Content_After
          Content_Before
          Phone_Number
        }
      } 
      strapiGlobalConfig { 
        Campaign_Footer_Office_Address 
      }  
    }
`
export default CampaignTemplate 