import { Container, Form, Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import ReactMarkdown from "react-markdown/with-html";
import CookieConsent from "../CookieConsent/CookieConsent"


const Footer = (props) => {
  return <React.Fragment>
    <footer className="footer">
      <CookieConsent />
      {/* <Container className="footersec animate__animated"> */}
      <Container className="footersec">
        {/* footer primary start */}
        {props.footerDetails?.Campaign_Footer_Office_Address &&
          <div className="footer-primary ">
            <div className="branch-details">
              <ReactMarkdown source={props.footerDetails.Campaign_Footer_Office_Address} escapeHtml={false} />
            </div>
          </div>
        }

        {/* footer primary end*/}
      </Container>
    </footer>
  </React.Fragment>
}

export default Footer
