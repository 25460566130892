import { Container, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import ReactMarkdown from "react-markdown/with-html"
import RentalValuationForm from "../forms/rental-valuation-form";
import Grate from "../../images/google-rate.png";
import ReviewScore from "../reviewScore"
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";

const CampaignHeader = ({ image_url, strapiId, processedImages, Banner_Content, Contact_Number }) => {

    return (
        <section className="banner">
            <div className="image">
                {image_url != null &&
                    <ImageTransform imagesources={image_url} renderer="srcSet" imagename="campaign.Banner_Image.bannerimg" attr='Campaign Banner - Orlando Reid' imagetransformresult={processedImages} id={strapiId} />

                }

            </div>
            <Container className="banner-content-block">
                <div className="details-text">
                    <ReactMarkdown source={Banner_Content} escapeHtml={false} /> 
                    <div className="google-rating d-md-flex align-items-md-center">
                        <img src={Grate} alt="img" />
                        <a href={'https://maps.app.goo.gl/rsvVCgEawaSdeT7a6'} target="_blank"><ReviewScore /></a>
                      </div>
                </div>
                <div className="banner-form">
                    <RentalValuationForm /> 
                    <p>Simply fill out the form or contact us on</p>
                    <a href={`tel:${Contact_Number}`}>{Contact_Number}</a>
                </div>
            </Container>
        </section>

    )
}

export default CampaignHeader;
