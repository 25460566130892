import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import ImgStar from "../../images/icon-star.svg";
import axios from "axios"
import Grate from "../../images/google-review-logo.svg";
import ShowMoreText from 'react-show-more-text';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import ReviewScore from "../reviewScore"
// English.
import en from 'javascript-time-ago/locale/en'



// Create formatter (English).

const postsPerPage = 10;
let arrayForHoldingPosts = [];



const StaticPage = () => {
 
 

  const postsToShow= [
    {
      starRating: "FIVE",
      comment: "Full marks to my property manager Sam El Jani. I am very happy with her work. She always responds quickly and gives great advice and suggestions. She has been really busy for us recently and is taking care of multiple conversations with different parties and doing a great job. Thank you Sam!",
      Data:"Mar 2024",
      reviewer:{
        profilePhotoUrl: "https://lh3.googleusercontent.com/a/AAcHTtdCKyl_tWEFu12L1qG_Ynw912ugkUUBnaDTubwuNud2=s120-c-rp-mo-br100",
        displayName: "Thermas"
      } 
    },
    {
      starRating: "FIVE",
      comment: " A great service from Sonny in relation to repairs in my home. Great time management and regular check ins with myself to ensure repair schedule was on target. Not afraid to cc people into email to get the job done! Bravo Sonny!",
      Data:"Apr 2024",
      reviewer:{
        profilePhotoUrl: "https://lh3.googleusercontent.com/a/AAcHTtfgPe3ZxAzXzxflTugzRawZHW9Ef-fJXKdPx0Hmpy5H=s120-c-rp-mo-br100",
        displayName: "Slix Ninja"
      } 
    },
    {
      starRating: "FIVE",
      comment: "We as an accountant dealing with 100's of letting & property management companies had the best experience with Orlando Reid, so professional, can-do attitude and helping clients by going out of their way. I would also like to mention My Experience with Alison at Orlando Reid I would say one of their team's key players is always great and she always exceeds expectations.",
      Data:"December 2023",
      reviewer:{
        profilePhotoUrl: "https://lh3.googleusercontent.com/a/AAcHTtflV14fm3kDOp6BzNboixmF5ggyNZ2ZvyldoLoco3cC=s120-c-rp-mo-br100",
        displayName: "Yasir Muhammad"
      } 
    }
  ];
 
  
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1293,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '163px',
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '0px',
          dots: true,
          infinite: true,


        }
      }
    ]
  };



  return (
    <>

 
        <div className="static-page">
          <section className="sec-review">
            <Container>
              <div className="google-rating d-md-flex align-items-md-center">
                <img src={Grate} alt="img" />
                <a  href={'https://maps.app.goo.gl/rsvVCgEawaSdeT7a6'} target="_blank"><ReviewScore /></a>
              </div>
              <Slider {...settings}>
                {postsToShow.map((review) => {
                  if (review.starRating != "ONE" && review.starRating != "TWO" && review.starRating != "THREE") {
                    return <>
                      <div className="myList mx-2">
                        <div className="card-rating">
                          <ul className="rating-star d-flex">
                            {review.starRating == "ONE" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "TWO" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "THREE" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "FOUR" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>

                              </>
                            }
                            {review.starRating == "FIVE" &&
                              <>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                                <li>
                                  <img src={ImgStar} alt="img" />
                                </li>
                              </>
                            }
                          </ul>
                          <p className="more"> 
                              {review.comment} 

                          </p>
                          <div className="user-profile d-flex align-items-center">
                            <figure className="profile-img">
                              <img src={review.reviewer.profilePhotoUrl} alt="img" />
                            </figure>
                            <div className="profile-desc">
                              <span className="user-name">{review.reviewer.displayName}</span>
                              <span className="post-time">   {review.Data}  </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  }
                })}
              </Slider> 
            </Container>
          </section> 
        </div> 
    </>
  )
}

export default StaticPage