import { Container, Navbar } from 'react-bootstrap'; 
import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";
import { LogoMain, LogoReid } from '../icon';

const CampaignHeader = () => { 
  return (
    <header className="header2">
      <Container>
        <Navbar className="p-0 justify-content-center" expand="xl"> 
            <Navbar.Brand className="p-0" >
              <LogoMain /> <LogoReid />
            </Navbar.Brand> 
        </Navbar>
      </Container>
    </header>

  )
}

export default CampaignHeader;
