import { Container, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import ReactMarkdown from "react-markdown/with-html"
import RentalValuationForm from "../forms/rental-valuation-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";

const GridBlock = ({ Title, Content, Grid_List, CTA_Label, CTA_Link, gotohash }) => {

    return (
        <section className="grid-block">
            <Container className="grid-content-block">
                <div className="heading">
                    <h2>{Title}</h2>
                    <ReactMarkdown source={Content} escapeHtml={false} />
                </div>
                {Grid_List &&
                    <div className="list">
                        <Row>
                            {Grid_List.map((list) => {
                                let image_url = list.Image && list.Image.internal.description ? list.Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
                                return (
                                    <Col lg={4} md={6} className='pb-5'>
                                        <img src={image_url} />
                                        <h5>{list.Title}</h5>
                                        <p>{list.Content}</p>

                                    </Col>
                                )
                            })}
                        </Row>
                    </div>
                }
                {CTA_Label && CTA_Link === "contact_form" ?
                    <a href="javascript:void(0);" className={"btn btn-success btn"} onClick={() => { gotohash() }}>{CTA_Label}</a>
                    :
                    <Link to={CTA_Link} className={"btn btn-success btn"}>{CTA_Label}</Link>
                }

            </Container>
        </section>

    )
}

export default GridBlock;
