import { Container, Row, Col } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import ImageTransform from "../common/ggfx-client/module/components/image-transform";
import ReactMarkdown from "react-markdown/with-html"
import RentalValuationForm from "../forms/rental-valuation-form";
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from "jquery";

const ContentBlock = ({ Title, Content, Content_Block, CTA_Label, CTA_Link, gotohash, strapiCampaigns }) => {

    let processedImages = JSON.stringify({});
    if (strapiCampaigns.imagetransforms?.List_Image_Transforms) {
      processedImages = strapiCampaigns.imagetransforms.List_Image_Transforms;
    }
    return (
        <section className="content-block">
            <div className="heading">
                <h2>{Title}</h2>
                <ReactMarkdown source={Content} escapeHtml={false} />
            </div>
            {Content_Block &&
                <div>
                    {Content_Block.map((list, n) => {
                        let image_url = list.Image && list.Image.internal.description ? list.Image.internal.description.replace("File ", "").replace('"', '').replace('"', '') : '';
                        return (
                            <div className={`list ${list.BG_Color}`}>
                                <Row className={`list-row container`}>
                                    <Col md={6} className={`${(n & 1 == 1) ? 'order-md-1 odd' : 'order-md-2 even'} `}>
                                        {/* <img src={image_url} /> */}
                                        {image_url != null &&
                                            <ImageTransform imagesources={image_url} renderer="srcSet" imagename="campaign.List_Image.listimg" attr='Campaign List - Orlando Reid' imagetransformresult={processedImages} id={strapiCampaigns.strapiId} />

                                        }
                                    </Col>
                                    <Col md={6} className={`${(n & 1 == 1) ? 'order-md-2 even' : 'order-md-1 odd'}`}>
                                        <div className='content'>
                                            <h5>{list.Title}</h5>
                                            <ReactMarkdown source={list.Content} escapeHtml={false} />
                                            {list.CTA_Label && list.CTA_Link === "contact_form" ?
                                                <a href="javascript:void(0);" className={"btn btn-success btn"} onClick={() => { gotohash() }}>{list.CTA_Label}</a>
                                                :
                                                <Link to={list.CTA_Link} className={"btn btn-success btn"}>{list.CTA_Label}</Link>
                                            }
                                        </div>

                                    </Col>
                                </Row>
                            </div>

                        )
                    })}
                </div>
            }

        </section>

    )
}

export default ContentBlock;
